import { useEffect } from 'react'
import styled from 'styled-components'
import { type Field, type ZodSchema, useFieldArray } from '@sevenrooms/core/form'
import { FormNumberInput, Label } from '@sevenrooms/core/ui-kit/form'
import { Typography } from '@sevenrooms/react-components'
import type { ModifierMinMaxSchema } from '../modifierTypes/ModifierMinMax.zod'
import type { ModifiersSelected } from '../modifierTypes/Modifiers'

interface MinMaxSelectionProps {
  fields: Field<ZodSchema<typeof ModifierMinMaxSchema>>
  watch: (name?: string | string[]) => void
  selectedGroups: ModifiersSelected[] | undefined
  alreadySelectedGroups: ModifiersSelected[]
  getValues: (name?: string | string[]) => {
    group: string
    minAmount: number
    maxAmount: number
  }[]
}

const MinMaxSelection = ({ fields, watch, selectedGroups, alreadySelectedGroups, getValues }: MinMaxSelectionProps) => {
  const { fields: minMaxes, append, replace } = useFieldArray(fields, 'minMax')

  useEffect(() => {
    if (selectedGroups) {
      const minMaxesValues = getValues('minMax')
      replace([])
      selectedGroups.forEach(group => {
        const minMaxFound = minMaxesValues.find(minMax => minMax.group === group.name)
        const newItem = {
          minAmount: minMaxFound ? minMaxFound.minAmount : 0,
          maxAmount: minMaxFound ? minMaxFound.maxAmount : group.modifiersAvailable.length,
          group: group.name,
        }
        append(newItem)
      })
    }
  }, [selectedGroups, replace, append, getValues])

  useEffect(() => {
    if (alreadySelectedGroups) {
      alreadySelectedGroups.forEach(group => {
        const newItem = {
          minAmount: group.minRequired || 0,
          maxAmount: group.maxRequired || 0,
          group: group.name,
        }
        append(newItem)
      })
    }
  }, [alreadySelectedGroups, append])

  watch('minMax')

  return (
    <SettingsContainer>
      {minMaxes?.map((minMax, idx) => (
        <MinAndMaxSettingContainer key={minMax.group}>
          <Typography variant="h3" fontSize={20} fontWeight={600} color="">
            {minMax.group}
          </Typography>
          <Label primary="Minimum number of selection in this group" />
          <FormNumberInput field={fields.prop(`minMax.${idx}.minAmount`)} />
          <Label primary="Maximum number of selection in this group" />
          <FormNumberInput field={fields.prop(`minMax.${idx}.maxAmount`)} />
        </MinAndMaxSettingContainer>
      ))}
    </SettingsContainer>
  )
}

const MinAndMaxSettingContainer = styled.div`
  padding: 0.5rem 1.3rem 1.5rem 1.3rem;
  margin-top: 1rem;
  border: 1px #cccccc solid;
  border-radius: 7px;

  & > h2 {
    margin-bottom: 0.5rem;
  }
`
const SettingsContainer = styled.div`
  overflow-y: scroll;
  max-height: 595px;
`

export default MinMaxSelection
